import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import IconMessage from "./icons/icon-message";
import { Form } from "react-final-form";
import { Flex, Spinner, Text } from "theme-ui";
import RFFInput from "./form-fields/rff-fields/RFFInput";
import useSocket from "../utils/socket";
import { defaultLimit, defaultPage } from "../constants";
import { reverseArrayWithLastElementFirst } from "../utils/helpers";
import { getMessages } from "../apis";
import { getItem } from "../utils/storage";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";

const MAX_CHAR_LIMIT = 500;

const ChatUIWrapper = styled.div`
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 350px;
  height: 500px;
  background-color: ${(props) => props.theme.colors.inputBG};
  border-radius: 20px;
  box-shadow: 0 4px 20px ${(props) => props.theme.colors.opaqueBlack};
  display: ${(props) => (props.isOpen ? "block" : "none")};
  z-index: 1000;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 70%;
    height: 50%;
    bottom: 60px;
    right: 0;
    border-radius: 20px 20px 0 0;
  }

  @media (max-width: 480px) {
    height: 70%;
  }
`;

const ChatHeader = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  padding: 20px;
  border-radius: 20px 20px 0 0;
  text-align: center;
  font-size: 18px;

  @media (max-width: 768px) {
    padding: 15px;
    font-size: 16px;
  }
`;

const ChatBody = styled.div`
  padding: 20px;
  height: calc(100% - 140px);
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 15px;
    height: calc(100% - 130px);
  }
`;

const ChatFooter = styled.div`
  padding: 10px;
  border-top: 1px solid ${(props) => props.theme.colors.messageColor};
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};

  @media (max-width: 768px) {
    padding: 8px;
  }
`;

const ChatInputWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 5px 10px;

  @media (max-width: 768px) {
    padding: 5px;
    margin-right: 5px;
  }
`;

const SendButton = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
`;

const ChatToggleButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1001;
  background-color: ${(props) =>
    props.isDisabled
      ? props.theme.colors.lightGray
      : props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  border-radius: 50px;
  border: none;
  width: auto;
  height: 50px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 45px;
    padding: 0 12px;
  }
`;

const MessageBubble = styled.div`
  background-color: ${(props) =>
    props.isSentByUser ? props.theme.colors.primary : "#007ba7"};
  color: ${(props) => props.theme.colors.white};
  padding: 10px 15px;
  border-radius: 15px;
  margin-top: 10px;
  max-width: 80%;
  word-wrap: break-word;
  margin-left: ${({ isSentByUser }) => (isSentByUser ? "auto" : "0")};
  position: relative;
`;

const MessageInfo = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.black};
  text-align: right;
  width: ${({ isSentByUser }) => (isSentByUser ? "auto" : "80%;")};
`;

const UnreadBadge = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 12px;
`;
const TooltipWrapper = styled.div`
  position: absolute;
  top: -95px;
  left: -130px;
  right: -10px;
  background-color: red;
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 100px;
    margin-left: 0;
    border-width: 5px;
    border-style: solid;
    border-color: red transparent transparent transparent;
  }
`;

const CharacterCounter = styled.div`
  font-size: 9px;
  margin-right: 5px;
`;

const ChatUI = () => {
  const { t } = useTranslation();
  const chatUsername = getItem("chat_username");
  const chatToken = getItem("chat_token");
  const { conversationId } = chatToken !== "null" && jwtDecode(chatToken);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [page, setPage] = useState(defaultPage);
  const [limit, setLimit] = useState(defaultLimit);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isTooltip, setIsTooltip] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [lastMessage, setLastMessage] = useState(null);
  const [charCount, setCharCount] = useState(MAX_CHAR_LIMIT);
  const {
    sendMessage,
    chatId,
    setMessages,
    messages,
    chatBodyRef,
    joinConversation,
    // leaveConversation,
    loadConversations,
    lastActiveAt,
  } = useSocket(setUnreadCount, isChatOpen, setLastMessage);

  const onInputChange = (value) => {
    setCharCount(MAX_CHAR_LIMIT - value.length);
  };

  useEffect(() => {
    if (lastMessage) {
      setIsTooltip(true);
      const timer = setTimeout(() => {
        setIsTooltip(false);
        setLastMessage(null);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (loadConversations && lastActiveAt) {
      const lastActiveDate = new Date(lastActiveAt);

      const newMessagesCount = loadConversations?.filter(
        (data) =>
          new Date(data.sentAt) > lastActiveDate &&
          data?.sender?.role === "operator"
      ).length;
      setUnreadCount(newMessagesCount);
    }
  }, [loadConversations, lastActiveAt, isChatOpen]);

  const handleCloseChat = () => {
    // leaveConversation();

    setIsChatOpen(false);
    setUnreadCount(0);
  };

  const handleLoginClick = () => {
    if (!isChatOpen) {
      setUnreadCount(0);
    }
    joinConversation();
    setIsChatOpen((prevState) => {
      // if (prevState) {
      //    leaveConversation();
      // }

      setIsTooltip(false);
      return !prevState;
    });
  };

  const loadMessages = useCallback(async () => {
    try {
      const fetchedMessages = await getMessages(conversationId, page, limit);
      const reversedMessages = reverseArrayWithLastElementFirst(
        fetchedMessages?.data?.data || []
      );

      setMessages((prev) => {
        const filteredNewMessages = reversedMessages.filter(
          (newMsg) =>
            !prev.some(
              (msg) =>
                msg.sentAt === newMsg.sentAt && msg.content === newMsg.content
            )
        );
        const updatedMessages = [...prev, ...filteredNewMessages];

        return updatedMessages;
      });
    } catch (err) {
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [conversationId, page, limit, setMessages]);

  useEffect(() => {
    loadMessages();
    if (isChatOpen) {
      setUnreadCount(0);
    }
  }, [loadMessages, isChatOpen]);

  const handleScroll = useCallback(async () => {
    if (chatBodyRef.current.scrollTop === 0 && !isLoadingMore) {
      setIsLoadingMore(true);

      setPage((prevPage) => prevPage + 1);
      await loadMessages();

      setIsLoadingMore(false);
    }
  }, [chatBodyRef, isLoadingMore, loadMessages]);

  useEffect(() => {
    const chatBody = chatBodyRef.current;
    if (chatBody) {
      chatBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatBody) {
        chatBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [chatBodyRef, handleScroll]);

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      await sendMessage(values);
      setCharCount(MAX_CHAR_LIMIT);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const chatBody = chatBodyRef.current;
    if (chatBody) {
      chatBody.scrollTop = chatBody.scrollHeight;
    }
  }, [messages]);

  return (
    <>
      <Flex
        sx={{
          position: "fixed",
          flexDirection: "column-reverse",
          bottom: "20px",
          right: "20px",
          zIndex: 1001,
        }}
      >
        <ChatToggleButton
          onClick={() => chatToken !== "null" && handleLoginClick()}
          isDisabled={chatToken === "null"}
          onMouseMove={() => chatToken === "null" && setIsTooltipVisible(true)}
          onMouseLeave={() =>
            chatToken === "null" && setIsTooltipVisible(false)
          }
        >
          {t("chat_with_us")}
          <IconMessage width="24px" height="18px" />
          {unreadCount > 0 && <UnreadBadge>{unreadCount}</UnreadBadge>}
        </ChatToggleButton>
        <TooltipWrapper isVisible={isTooltipVisible || isTooltip}>
          {lastMessage ? lastMessage : t("connection_failed")}
        </TooltipWrapper>
      </Flex>
      <ChatUIWrapper isOpen={isChatOpen}>
        <CloseButton onClick={handleCloseChat}>×</CloseButton>
        <ChatHeader>{t("live_chat")}</ChatHeader>
        <ChatBody ref={chatBodyRef}>
          {!isLoading ? (
            <>
              {isLoadingMore && (
                <Flex
                  sx={{
                    width: "100%",
                    height: "70px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner />
                </Flex>
              )}
              {messages?.length > 0 ? (
                messages?.map((conversation) => (
                  <div key={conversation.id}>
                    <MessageBubble
                      isSentByUser={conversation.senderName === chatUsername}
                    >
                      {conversation.content}
                    </MessageBubble>
                    <MessageInfo
                      isSentByUser={conversation.senderName === chatUsername}
                    >
                      {conversation.senderName === chatUsername
                        ? new Date(conversation.sentAt).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        : `${
                            conversation.label ?? conversation.senderName
                          } - ${new Date(
                            conversation.sentAt
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}`}
                    </MessageInfo>
                  </div>
                ))
              ) : (
                <Text
                  sx={{
                    width: "100%",
                    height: "100%",
                    alignContent: "center",
                    textAlign: "center",
                  }}
                >
                  {t("no_messages")}
                </Text>
              )}
            </>
          ) : (
            <Flex
              sx={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spinner />
            </Flex>
          )}
        </ChatBody>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, form, values }) => (
            <form
              onSubmit={(event) => {
                handleSubmit(event);
                form.reset();
              }}
            >
              <ChatFooter>
                <ChatInputWrapper>
                  <RFFInput
                    type="text"
                    placeholder={t("enter_your_message")}
                    name="content"
                    autoComplete="off"
                    maxLength={MAX_CHAR_LIMIT}
                    sx={{
                      shadow: "0",
                      borderRadius: "50px",
                      width: "100%",
                      backgroundColor: "transparent",
                      borderColor: "lightGray",
                    }}
                    onChange={(e) => onInputChange(e)}
                    disabled={!messages?.length && isLoading}
                  />
                </ChatInputWrapper>
                <CharacterCounter remainingChars={charCount}>
                  {charCount} / {MAX_CHAR_LIMIT}
                </CharacterCounter>

                <SendButton
                  disabled={
                    !values.content?.trim() || (!messages?.length && isLoading)
                  }
                >
                  ➤
                </SendButton>
              </ChatFooter>
            </form>
          )}
        </Form>
      </ChatUIWrapper>
    </>
  );
};

export default ChatUI;
