import { login } from "../apis";
import exceptionHandler from "../apis/exceptionHandler";

export const submitLogin = async (
  values,
  authDispatch,
  history,
  setErrorMessage,
  setIsLoading
) => {
  try {
    setIsLoading(true);
    const response = await login(values);
    authDispatch({
      type: "LOGIN",
      payload: {
        token: response.data.token,
        password_change_required: response.data.password_change_required,
        invoice_login: response.data.invoice_login,
        chat_token: response.data.chat_token,
        chat_username: response.data.chat_username,
      },
    });
    if (history) {
      history.push("/trade-in");
    }
  } catch (e) {
    return exceptionHandler(
      e,
      (error) => {
        setErrorMessage(error);
      },
      (errors) => {
        return errors;
      }
    );
  } finally {
    setIsLoading(false);
  }
};
