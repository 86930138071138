import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { Field } from "react-final-form";
import { useQuery } from "react-query";
import { AsyncPaginate } from "react-select-async-paginate";
import { Box, Label } from "theme-ui";
import { getCountries } from "../../../apis";
import { RFFSelectCountryCustomStyles } from "../../../constants";
import RFFErrorMessage from "./RFFErrorMessage";
import { getdataOptions } from "../../../utils/helpers";

const RFFSelectCountry = ({ name, label, sx }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [selectedOption, setSelectedOption] = useState({
    value: null,
    label: "",
  });
  const { data, isLoading } = useQuery([currentPage, search], getCountries);

  const loadOptions = useCallback(async () => {
    const options = getdataOptions(data?.data);
    const offset = (currentPage - 1) * 10;
    return {
      options: options,
      hasMore: offset + 10 < data?.total,
      additional: {
        page: currentPage + 1,
      },
    };
  }, [data?.data, data?.total, currentPage]);

  return (
    <Field
      name={name}
      render={({ input, meta }) => (
        <Box
          sx={{
            width: "100%",
            position: "relative",
            ...sx,
          }}
        >
          {label && <Label sx={{ marginBottom: "2px" }}>{label}</Label>}
          <AsyncPaginate
            {...input}
            loadOptions={!isLoading && loadOptions}
            isSearchable
            additional={{
              currentPage,
            }}
            placeholder={
              !isLoading
                ? selectedOption.label || data?.data?.[0]?.country
                : undefined
            }
            onMenuScrollToBottom={() => setCurrentPage((prev) => prev + 1)}
            onInputChange={setSearch}
            onChange={(newValue) => (
              input.onChange(newValue.value),
              setSelectedOption({
                value: newValue.value,
                label: newValue.label,
              })
            )}
            styles={RFFSelectCountryCustomStyles(meta)}
          />
          {meta.touched && meta.error && (
            <Label sx={{ marginBottom: "0.7rem", marginTop: "0.5rem" }}>
              <RFFErrorMessage errorMessage={meta.error} />
            </Label>
          )}
        </Box>
      )}
    />
  );
};

RFFSelectCountry.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  sx: PropTypes.object,
};

export default RFFSelectCountry;
