import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";
import IconEdit from "../../../components/icons/icon-edit";
import { useMutation, useQuery, useQueryCache } from "react-query";
import {
  changeAuthCode,
  getCustomerProfile,
  getCustomerYukaPoints,
} from "../../../apis/index";
import WithLoader from "../../../components/WithLoader";
import { Form } from "react-final-form";
import RFFInputField from "../../unauthenticated/Login/RFFInputField";
import { isRequired } from "../../../utils/validators";
import { useTranslation } from "react-i18next";
import {
  formattedAddress,
  formattedDeliveryAddress,
} from "../../../utils/address";
import {
  getEuropeanDateFormat,
  getFormatedYukaPoints,
} from "../../../utils/helpers";
import IconEye from "../../../components/icons/icon-eye";
import IconEyeClose from "../../../components/icons/icon-eye-close";
import { useToast } from "../../../contexts/AlertProvider";
import exceptionHandler from "../../../apis/exceptionHandler";

const { Box, Heading, Text, Flex, Button } = require("theme-ui");
const { default: Container } = require("../../../components/Container");

const ProfileContiner = styled(Box)`
  margin: 3rem 0;

  .container {
    max-width: 64rem;
  }

  .page-header {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;

    .heading {
      display: inline-flex;
      align-items: center;
      padding-bottom: 0.5rem;
      color: ${(props) => props.theme.colors.primary};
      border-bottom: 3px solid ${(props) => props.theme.colors.secondary};
    }

    .svg-icon {
      margin-right: 0.5rem;
      height: 1.75rem;
      width: 1.75rem;
      fill: ${(props) => props.theme.colors.primary};
    }
  }

  .profile-details-container {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 12rem auto;

    .yp-details {
      text-align: right;

      .label {
        font-size: 0.75rem;
      }

      .value {
        font-size: 1.25rem;
        font-weight: 600;
      }
    }

    .profile-details {
      .info-block {
        margin-bottom: 2.5rem;

        .section-title {
          font-size: 1.25rem;
          font-weight: 500;
          color: ${(props) => props.theme.colors.primary};
          margin-bottom: 1.5rem;
        }

        .authentication-code-actions {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;

          .input-container {
            min-width: 18rem;
            margin-right: 0.5rem;
          }

          .btn {
            margin: 0 0.5rem;
          }
        }
      }
    }
  }

  .auth-placeholder {
    align-items: center;
    min-width: 15rem;
    border: 1px solid ${(props) => props.theme.colors.gray};
    border-radius: 0.25rem;

    > div {
      flex: 1 1 auto;
      padding: 0 1rem;
    }

    .icon-btn {
      border-left: 1px solid ${(props) => props.theme.colors.gray};
      border-radius: 0;
    }
  }

  ${(props) => props.theme.customBreakpoints[2]} {
    .profile-details-container {
      grid-gap: 0;

      .yp-details {
        grid-column: span 2;
        text-align: left;
        margin-bottom: 1.5rem;
      }

      .profile-details {
        grid-column: span 2;
      }
    }
  }

  .text-primary {
    color: ${(props) => props.theme.colors.primary};
  }

  .text-secondary {
    color: ${(props) => props.theme.colors.secondary};
    fill: ${(props) => props.theme.colors.secondary};
  }

  .info-error-text {
    ont-size: 80%;
    color: ${(props) => props.theme.colors.danger};
    font-style: italic;
    margin-top: -1rem;
    padding: 1rem 0;
    display: block;
  }
`;

const LabelValueList = styled(Flex)`
  margin-bottom: 1rem;
  align-items: flex-start;

  .label {
    flex: 1 1 15rem;
    max-width: 15rem;
    min-width: 15rem;
    color: ${(props) => props.theme.colors.secondaryText};
  }

  .value {
    flex: 1 1 auto;
  }

  ${(props) => props.theme.customBreakpoints[0]} {
    flex-wrap: wrap;

    .label {
      margin-bottom: 0.5rem;
      width: 100%;
      flex: 1 1 auto;
      max-width: none;
    }
  }
`;

const Profile = () => {
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const toast = useToast();

  const [hideEditBox, setHideEditBox] = useState(true);
  const [hideAuth, setHideAuth] = useState(true);

  const { data: profile, isLoading: isLoadingProfile } = useQuery(
    "profile",
    getCustomerProfile
  );
  const profileData = profile?.data || {};
  const {
    primary_address,
    secondary_address,
    stock_list_csv_url,
    stock_list_json_url,
    stock_list_itscope_url,
  } = profileData;

  const { data: yukapoints, isLoading: isLoadingYukapoints } = useQuery(
    "yuka-points",
    getCustomerYukaPoints
  );
  const yukaData = yukapoints?.data || {};

  const [mutate, { isLoading: isUpdatingAuthCode }] = useMutation(
    changeAuthCode,
    {
      onSuccess: (data) => {
        toast(t("authentication_code_updated_successfully"), {
          type: "success",
        });
        const requestData = JSON.parse(data.config.data);
        queryCache.setQueryData(["profile"], {
          data: { ...profileData, authcode: requestData.new_authcode },
        });
      },
      onError: (error) => {
        exceptionHandler(
          error,
          (error) => {
            toast(error, { type: "error" });
          },
          (errors) => {
            // eslint-disable-next-line no-console
            console.log("auth_code_update_error", errors);
          }
        );
      },
    }
  );
  const onSubmit = useCallback(
    async (values) => {
      await mutate(values);
      setHideEditBox(true);
    },
    [mutate]
  );

  return (
    <WithLoader
      isLoading={isLoadingProfile || isLoadingYukapoints}
      width={"100vw"}
      height={"40rem"}
    >
      <ProfileContiner>
        <Container className="container">
          <Box className="page-header">
            <Heading className="heading">
              <Text sx={{ fontWeight: "300" }}>{t("hello")}</Text>,{" "}
              {profileData.name}!
            </Heading>
          </Box>

          <Box className="profile-details-container">
            <Box className="yp-details">
              <Box sx={{ marginBottom: "1.25rem" }}>
                <Text className="label">{t("you_have")}</Text>
                <Text className="value text-secondary">
                  {getFormatedYukaPoints(yukaData.available_points)}
                </Text>
                <Text className="text-primary">{t("yuka_points")}!</Text>
              </Box>

              <Box>
                <Text className="label">{t("activation_date")}</Text>
                <Text className="value text-secondary">
                  {getEuropeanDateFormat(yukaData?.activation_date)?.substr(
                    0,
                    10
                  ) || `${t("not_activated")}`}
                </Text>
              </Box>
            </Box>
            <Box className="profile-details">
              <Box className="info-block">
                <Text className="section-title">
                  {t("account_information")}
                </Text>
                <Box>
                  <LabelValueList>
                    <Box className="label">Name</Box>
                    <Text className="value">{profileData?.name}</Text>
                  </LabelValueList>
                  <LabelValueList>
                    <Box className="label">{t("email")}</Box>
                    <Text className="value">{profileData?.email}</Text>
                  </LabelValueList>
                  <LabelValueList>
                    <Box className="label">{t("authentication_code")}</Box>
                    <div>
                      {hideEditBox ? (
                        <Box className="value">
                          <Flex sx={{ justifyContent: "space-between" }}>
                            <Flex className="auth-placeholder">
                              <Text>
                                {hideAuth ? "********" : profileData?.authcode}
                              </Text>
                              <Button
                                variant="iconButton"
                                className="icon-btn"
                                onClick={() => setHideAuth(!hideAuth)}
                              >
                                {hideAuth ? <IconEye /> : <IconEyeClose />}
                              </Button>
                            </Flex>
                            <Flex
                              onClick={() => setHideEditBox(false)}
                              className="text-secondary"
                              sx={{
                                alignItems: "center",
                                cursor: "pointer",
                                paddingX: "1.5rem",
                              }}
                            >
                              <IconEdit style={{ marginRight: "0.5rem" }} />
                              {t("edit")}
                            </Flex>
                          </Flex>
                        </Box>
                      ) : (
                        <Form
                          onSubmit={onSubmit}
                          initialValues={{
                            new_authcode: profileData?.authcode,
                          }}
                          validate={(values) => ({
                            new_authcode: isRequired(values.new_authcode),
                          })}
                          render={({ handleSubmit }) => (
                            <WithLoader isLoading={isUpdatingAuthCode}>
                              <form onSubmit={handleSubmit}>
                                <Box className="authentication-code-actions">
                                  <Box>
                                    <RFFInputField
                                      placeholder={
                                        "Enter new authentication code"
                                      }
                                      type={"text"}
                                      name={"new_authcode"}
                                      onChange={() => null}
                                    />
                                    <span className="info-error-text">
                                      *
                                      {t(
                                        "changing_auth_code_will_affect_access_to_api"
                                      )}
                                    </span>
                                  </Box>

                                  <Box>
                                    <Button
                                      variant="secondary"
                                      className="btn"
                                      type="submit"
                                    >
                                      {t("update")}
                                    </Button>
                                    <Button
                                      variant="primary"
                                      type="button"
                                      className="btn"
                                      onClick={() => setHideEditBox(true)}
                                    >
                                      {t("cancel")}
                                    </Button>
                                  </Box>
                                </Box>
                              </form>
                            </WithLoader>
                          )}
                        />
                      )}
                    </div>
                  </LabelValueList>
                </Box>
              </Box>
              <LabelValueList>
                <Box className="label">{t("view_stocklist_csv")}: </Box>
                <Text className="value">
                  <a
                    rel={"noreferrer"}
                    target="_blank"
                    href={stock_list_csv_url}
                  >
                    {stock_list_csv_url}
                  </a>
                </Text>
              </LabelValueList>
              <LabelValueList>
                <Box className="label">{t("view_stocklist_json")}: </Box>
                <Text className="value">
                  <a
                    rel={"noreferrer"}
                    target="_blank"
                    href={stock_list_json_url}
                  >
                    {stock_list_json_url}
                  </a>
                </Text>
              </LabelValueList>
              <LabelValueList>
                <Box className="label">{t("view_stocklist_itscope")}: </Box>
                <Text className="value">
                  <a
                    rel={"noreferrer"}
                    target="_blank"
                    href={stock_list_itscope_url}
                  >
                    {stock_list_itscope_url}
                  </a>
                </Text>
              </LabelValueList>
              <Box className="info-block">
                <Text className="section-title">{t("delivery_address")}</Text>
                <Box>
                  <LabelValueList>
                    <Box className="label">{t("primary_address")}</Box>
                    <Text className="value">
                      {formattedAddress(
                        primary_address?.street,
                        primary_address?.no,
                        primary_address?.postal,
                        primary_address?.city,
                        primary_address?.country
                      )}
                    </Text>
                  </LabelValueList>
                  <LabelValueList>
                    <Box className="label">{t("delivery_address")}</Box>
                    <Text className="value">
                      {secondary_address?.map((address, index) => {
                        return (
                          <div key={index}>
                            {formattedDeliveryAddress(
                              address?.firstname,
                              address?.com_lastname,
                              address?.street,
                              address?.no,
                              address?.postal,
                              address?.city,
                              address?.country
                            )}
                          </div>
                        );
                      })}
                    </Text>
                  </LabelValueList>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </ProfileContiner>
    </WithLoader>
  );
};

export default Profile;
