import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AutoSizer, List } from "react-virtualized";
import "react-virtualized/styles.css";
import { Box, Button, Flex, Grid, Text } from "theme-ui";
import DirectBuyLogo from "../../../assets/images/directbuy.png";
import {
  validCurreny,
  rightInputStyle,
  productsTableViewMinWidth,
  tradeInMaxInputValue,
} from "../../../constants";
import themes from "../../../themes";
import {
  calculateMinDate,
  tradeINAddProductButtonCheckDisable,
} from "../../../utils/helpers";
import InputDatePicker from "../../form-fields/InputDatePicker";
import InputGroup from "../../form-fields/InputGroup";
import InputNumber from "../../form-fields/InputNumber";
import IconPlus from "../../icons/icon-plus";

const MobileProductsTable = ({
  stockList,
  cartProducts,
  handleAddToCart,
  handleChange,
  handleValues,
  onScroll,
  errorInputs,
  focusId,
  setFocusId,
  windowWidth,
  windowHeight,
}) => {
  const { t } = useTranslation();
  const productData = useMemo(() => {
    const view = stockList?.map((item, key) => {
      const product =
        cartProducts?.length > 0 &&
        cartProducts.find(
          (cartItem) => cartItem?.article_no === item?.article_no
        );

      return {
        id: key,
        data: (
          <Flex
            sx={{
              flexDirection: "column",
              width: "100%",
              height: "100%",
              backgroundColor:
                (product && themes.colors.lightGray) ||
                (item?.direct_buy_price !== -1
                  ? themes.colors.directBuyProductBG
                  : themes.colors.white),
              borderBottom: `0.1em solid ${themes.colors.tableBgGray}`,
              paddingLeft: "0.5em",
              paddingRight: "0.5em",
              margin: "0 2em 0 0",
              verticalAlign: "middle",
              "@media screen and (max-width:625px)": {
                margin: "0 2em 0 0",
              },
              "@media screen and (max-width:325px)": {
                margin: "0",
              },
            }}
            key={key}
          >
            <Flex sx={{ width: "100%", justifyContent: "space-between" }}>
              <Flex sx={{ width: "100%", flexDirection: "column" }}>
                <Text
                  sx={{
                    fontWeight: "bold",
                    padding: "0.3em 0",
                    marginRight: "0.7rem",
                  }}
                >
                  {item?.description}
                </Text>
                <Text
                  sx={{
                    fontWeight: "bold",
                    padding: "0.3em 0",
                    marginRight: "0.7rem",
                  }}
                >
                  {`${t("european_article_number_short")} : ${item?.ean}`}
                </Text>
              </Flex>
              <Flex sx={{ marginRight: "1.5em" }}>
                <Box sx={{ width: "20px", height: "20px" }}>
                  <Button
                    type={"button"}
                    variant="primaryIconButton"
                    className="icon-button"
                    onClick={() => handleAddToCart(key, item, handleValues)}
                    sx={{
                      height: "1.7em",
                      width: "1.7em",
                      marginTop: "0.5em",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    disabled={
                      product ||
                      tradeINAddProductButtonCheckDisable(
                        product,
                        handleValues,
                        item
                      )
                    }
                  >
                    <IconPlus />
                  </Button>
                </Box>
              </Flex>
            </Flex>
            <Grid columns={2}>
              <Grid columns={1} gap={0}>
                <Text
                  sx={{
                    "@media screen and (max-width:280px)": {
                      fontSize: "0.8rem",
                    },
                  }}
                >{`${t("unit_price")} ( ${validCurreny} )`}</Text>
                {item?.direct_buy_price !== -1 ? (
                  <InputGroup
                    key={key}
                    type="number"
                    name="unit_price"
                    id={`unit_price${key}`}
                    label={""}
                    directBuyPrice={
                      item?.direct_buy_price !== -1 && item?.direct_buy_price
                    }
                    value={
                      product?.unit_price ||
                      handleValues?.[item?.article_no]?.unit_price ||
                      null
                    }
                    iconPlacement="suffix"
                    toolTipMessage={
                      (product?.unit_price ||
                        handleValues?.[item?.article_no]?.unit_price) &&
                      t("trade_in_input_group_max_value_error_message")
                    }
                    toolTipStyle={{
                      display:
                        (product?.unit_price ||
                          handleValues?.[item?.article_no]?.unit_price) >
                        item?.direct_buy_price + 10
                          ? "block"
                          : "none",
                    }}
                    onChange={(id, name, value) =>
                      handleChange(id, name, value, item)
                    }
                    onFocus={(e) => setFocusId(e.target.id)}
                    focusId={focusId}
                    onBlur={(id, name, value) =>
                      handleChange(id, name, value, item)
                    }
                    rightText={`< ${item?.direct_buy_price} ${validCurreny}`}
                    errorInputs={errorInputs}
                    disabled={product}
                    autocomplete="off"
                  />
                ) : (
                  <InputNumber
                    key={key}
                    type="number"
                    name="unit_price"
                    id={`unit_price${key}`}
                    label={""}
                    value={
                      handleValues?.[item?.article_no]?.unit_price ||
                      product?.unit_price ||
                      null
                    }
                    iconPlacement="suffix"
                    max={tradeInMaxInputValue}
                    onChange={(id, name, value) =>
                      handleChange(id, name, value, item)
                    }
                    toolTipMessage={
                      (handleValues?.[item?.article_no]?.unit_price ||
                        product?.unit_price) > tradeInMaxInputValue &&
                      t("trade_in_input_group_max_value_error_message")
                    }
                    toolTipStyle={{
                      display:
                        (handleValues?.[item?.article_no]?.unit_price ||
                          product?.unit_price) > tradeInMaxInputValue
                          ? "block"
                          : "none",
                    }}
                    onBlur={(id, name, value) =>
                      handleChange(id, name, value, item)
                    }
                    onFocus={(e) => setFocusId(e.target.id)}
                    focusId={focusId}
                    errorInputs={errorInputs}
                    disabled={product}
                    autocomplete="off"
                  />
                )}
              </Grid>
              <Grid columns={1} gap={0}>
                <Text
                  sx={{
                    "@media screen and (max-width:280px)": {
                      fontSize: "0.8rem",
                    },
                  }}
                >
                  {t("minimum_order_quantity_short")}
                </Text>
                <InputNumber
                  key={key}
                  type="number"
                  name="min_order_quantity"
                  id={`min_order_quantity${key}`}
                  iconPlacement="suffix"
                  placeholder={
                    item?.quantity_set <= 100 && `max. ${item?.quantity_set}`
                  }
                  min={1}
                  max={item?.quantity_set}
                  value={
                    handleValues?.[item?.article_no]?.min_order_quantity ||
                    product?.min_order_quantity ||
                    null
                  }
                  onChange={(id, name, value) =>
                    handleChange(id, name, value, item)
                  }
                  onBlur={(id, name, value) =>
                    handleChange(id, name, value, item)
                  }
                  disabled={product}
                  autocomplete="off"
                  onFocus={(e) => setFocusId(e.target.id)}
                  focusId={focusId}
                  isDirectBuy={item?.direct_buy_price !== -1}
                  toolTipMessage={
                    item?.direct_buy_price !== -1
                      ? `${t(
                          "trade_in_direct_buy_min_order_quantity_input_max_value_error_message"
                        )}  ${item?.quantity_set}`
                      : `${t(
                          "trade_in_min_order_quantity_input_max_value_error_message"
                        )}  ${item?.quantity_set}`
                  }
                  errorInputs={errorInputs}
                />
              </Grid>
            </Grid>
            <Grid columns={2} sx={{ marginTop: "0.5em" }}>
              <Grid columns={1} gap={0}>
                <Text
                  sx={{
                    "@media screen and (max-width:280px)": {
                      fontSize: "0.8rem",
                    },
                  }}
                >
                  {t("take_all_quantity")}
                </Text>
                <InputGroup
                  key={key}
                  type="number"
                  max={tradeInMaxInputValue}
                  name="take_all_quantity"
                  id={`take_all_quantity${key}`}
                  placeHolder=""
                  value={
                    product?.take_all_quantity ||
                    handleValues?.[item?.article_no]?.take_all_quantity ||
                    null
                  }
                  min={
                    handleValues?.[item?.article_no]?.min_order_quantity ||
                    product?.min_order_quantity ||
                    item?.quantity_set
                  }
                  iconPlacement="suffix"
                  leftInputStyle={{
                    width: "70px",
                  }}
                  rightInputStyle={{
                    rightInputStyle,
                  }}
                  onFocus={(e) => setFocusId(e.target.id)}
                  focusId={focusId}
                  toolTipMessage={
                    (product?.take_all_quantity ||
                      handleValues?.[item?.article_no]?.take_all_quantity) <=
                    item?.quantity_set
                      ? `${t("total_quantity_tooltip_message")} > ${
                          item?.quantity_set
                        }`
                      : t("total_quantity_tooltip_message")
                  }
                  toolTipStyle={
                    (product?.take_all_quantity ||
                      handleValues?.[item?.article_no]?.take_all_quantity) <=
                    item?.quantity_set
                      ? {
                          marginLeft: "2.5em",
                          backgroundColor: themes.colors.danger,
                          color: themes.colors.white,
                        }
                      : {
                          marginLeft: "1em",
                          backgroundColor: themes.colors.warning,
                          color: themes.colors.black,
                        }
                  }
                  onChange={(id, name, value) =>
                    handleChange(id, name, value, item)
                  }
                  onBlur={(id, name, value) =>
                    handleChange(id, name, value, item)
                  }
                  rightText={t("quantity_short")}
                  disabled={
                    !handleValues?.[item?.article_no]?.min_order_quantity ||
                    product ||
                    (handleValues?.[item?.article_no]?.min_order_quantity ||
                      product?.min_order_quantity) !== item?.quantity_set
                  }
                  errorInputs={errorInputs}
                  autocomplete="off"
                />
              </Grid>
              <Grid columns={1} gap={0}>
                <Text
                  sx={{
                    "@media screen and (max-width:280px)": {
                      fontSize: "0.8rem",
                    },
                  }}
                >{`${t("take_all_unit_price")}`}</Text>
                <InputGroup
                  key={key}
                  type="number"
                  name="take_all_unit_price"
                  id={`take_all_unit_price${key}`}
                  placeHolder=""
                  max={tradeInMaxInputValue}
                  value={
                    product?.take_all_unit_price ||
                    handleValues?.[item?.article_no]?.take_all_unit_price ||
                    null
                  }
                  iconPlacement="suffix"
                  leftInputStyle={{ width: "80px" }}
                  rightInputStyle={{
                    ...rightInputStyle,
                  }}
                  onFocus={(e) => setFocusId(e.target.id)}
                  focusId={focusId}
                  toolTipMessage={
                    (product?.take_all_unit_price ||
                      handleValues?.[item?.article_no]?.take_all_unit_price) >=
                    tradeInMaxInputValue
                      ? t("trade_in_input_group_max_value_error_message")
                      : t("total_price_tooltip_message")
                  }
                  toolTipStyle={
                    (product?.take_all_unit_price ||
                      handleValues?.[item?.article_no]?.take_all_unit_price) >=
                    tradeInMaxInputValue
                      ? {
                          marginRight: "2em",
                          backgroundColor: themes.colors.danger,
                          color: themes.colors.white,
                        }
                      : {
                          marginRight: "2em",
                          backgroundColor: themes.colors.warning,
                          color: themes.colors.black,
                        }
                  }
                  onChange={(id, name, value) =>
                    handleChange(id, name, value, item)
                  }
                  onBlur={(id, name, value) =>
                    handleChange(id, name, value, item)
                  }
                  rightText={` ${validCurreny}`}
                  disabled={
                    !handleValues?.[item?.article_no]?.min_order_quantity ||
                    product ||
                    (handleValues?.[item?.article_no]?.min_order_quantity ||
                      product?.min_order_quantity) !== item?.quantity_set
                  }
                  errorInputs={errorInputs}
                  autocomplete="off"
                />
              </Grid>
            </Grid>
            <Grid columns={2} sx={{ marginTop: "0.2em" }}>
              <Grid columns={1} gap={0}>
                <Text
                  sx={{
                    "@media screen and (max-width:280px)": {
                      fontSize: "0.8rem",
                    },
                  }}
                >
                  {t("delivery_date_short")}
                </Text>
                <InputDatePicker
                  id={`delivery_date${key}`}
                  name="delivery_date"
                  minimumDate={calculateMinDate()}
                  value={
                    handleValues?.[item?.article_no]?.delivery_date ||
                    product?.delivery_date ||
                    null
                  }
                  defaultValue={calculateMinDate()}
                  onChange={(id, name, value) =>
                    handleChange(id, name, value, item)
                  }
                  specialDate={item?.direct_buy_price !== -1}
                  style={{
                    width: "100px",
                    height: "80%",
                    borderRadius: "0.2rem",
                    border: `0.3px solid ${themes.colors.productTableInputBorder}`,
                    zIndex: "9999",
                  }}
                  disabled={product}
                  autocomplete="off"
                />
              </Grid>
              <Grid columns={1} gap={0} sx={{ marginTop: "2em" }}>
                {item?.direct_buy_price !== -1 && (
                  <img
                    src={DirectBuyLogo}
                    width="70px"
                    height="auto"
                    alt="direct_buy"
                  />
                )}
              </Grid>
            </Grid>
          </Flex>
        ),
      };
    });
    return view;
  }, [
    cartProducts,
    errorInputs,
    focusId,
    handleAddToCart,
    handleChange,
    handleValues,
    setFocusId,
    stockList,
    t,
  ]);
  return (
    <Flex
      sx={{
        width: `calc(${windowWidth}px - 2em)`,
        flexDirection: "column",
        height:
          windowWidth === productsTableViewMinWidth
            ? `calc(${windowHeight}px-2em )`
            : `${windowHeight}px`,
      }}
    >
      <AutoSizer>
        {({ width = windowWidth, height = windowHeight }) => (
          <Box
            sx={{
              width: width,
              height: height,
            }}
          >
            <List
              width={width}
              height={height}
              rowCount={productData.length}
              columnCount={1}
              onRowsRendered={({ stopIndex, overscanStopIndex }) =>
                onScroll({
                  rowOverscanStopIndex: overscanStopIndex,
                  rowStopIndex: stopIndex,
                })
              }
              rowHeight={productData.length === 1 ? 460 : 245}
              rowRenderer={({ key, index, style }) => {
                if (productData.length === 1) {
                  return (
                    <>
                      <div
                        key={key}
                        style={{
                          height: "240px",
                        }}
                      >
                        {productData[index]?.data}
                      </div>
                      <div
                        style={{
                          height: "230px",
                        }}
                      />
                    </>
                  );
                } else {
                  return (
                    <>
                      <div key={key} style={style}>
                        {productData[index]?.data}
                      </div>
                    </>
                  );
                }
              }}
            />
          </Box>
        )}
      </AutoSizer>
    </Flex>
  );
};

MobileProductsTable.propTypes = {
  productData: PropTypes.object,
  isLoading: PropTypes.bool,
  stockList: PropTypes.object,
  onScroll: PropTypes.func,
  cartProducts: PropTypes.object,
  handleAddToCart: PropTypes.func,
  handleChange: PropTypes.func,
  handleValues: PropTypes.object,
  canFetchMore: PropTypes.bool,
  fetchMore: PropTypes.func,
  page: PropTypes.number,
  isFetching: PropTypes.func,
};

export default MobileProductsTable;
