import React, { createContext, useContext, useReducer } from "react";
import { getItem, removeItem, setItem } from "../utils/storage";
import PropTypes from "prop-types";
import JWTValidator from "../utils/JWTValidator";

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "LOGIN":
          setItem("token", action.payload.token);
          setItem(
            "password_change_required",
            action.payload.password_change_required
          );
          setItem("invoice_login", action.payload.invoice_login);
          setItem("chat_token", action.payload.chat_token);
          setItem("chat_username", action.payload.chat_username);
          return {
            ...state,
            authenticated: true,
            password_change_required: action.payload.password_change_required,
            invoice_login: action.payload.invoice_login,
          };
        case "LOGOUT":
          removeItem("token");
          removeItem("password_change_required");
          removeItem("invoice_login");
          removeItem("chat_token");
          removeItem("chat_username");
          return { authenticated: false };
        default:
          throw new Error("undefined action type used");
      }
    },
    {
      authenticated: !!getItem("token") && JWTValidator(getItem("token")),
      password_change_required: "true" === getItem("password_change_required"),
      invoice_login: "true" === getItem("invoice_login"),
    }
  );

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.element,
};

export default AuthProvider;

export const useAuthContext = () => {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }
  return context;
};

export const useAuthDispatch = () => {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }
  return context;
};

export const useLogout = () => {
  const authDispatch = useAuthDispatch();

  return () => {
    authDispatch({ type: "LOGOUT", payload: null });
  };
};
