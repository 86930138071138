import styled from "@emotion/styled";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Text, Spinner } from "theme-ui";
import { OverviewTabMenuColumns, TradeIn_Status } from "../../../constants";
import Pagination from "../Shop/Pagination";
import { transparentize } from "@theme-ui/color";
import { useQuery } from "react-query";
import { getTradeInOrder } from "../../../apis";
import themes from "../../../themes";
import { StatusComponent } from "../../../components/TradeIn/old-offer/StatusComponent";
import useMediaQuery from "../../../utils/useMediaQuery";
import IconFilter from "../../../components/icons/icon-filter";
import Menu from "../../../components/Menu";

export const Container = styled(Box)`
  margin: 3rem 1rem;

  .pagination-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    justify-content: flex-end;

    .filter-menu {
      border: 1px solid ${(props) => props.theme.colors.secondary};
      border-radius: 0.5rem;
      height: 2.2rem;
      color: ${(props) => props.theme.colors.primary};
      fill: ${(props) => props.theme.colors.primary};
      margin-right: 1.2rem;
      width: 340px;
      display: flex;
      text-align: center;

      .svg-icon {
        height: 1.25rem;
        width: 1.25rem;
      }

      &:hover {
        background-color: ${(props) =>
          transparentize(props.theme.colors.primaryText, 0.96)};
      }
       @media screen and (max-width: 570px) {
        width: 200px;  
        .svg-icon {
          height: 1rem;  
          width: 1rem;   
        }
      }
    }
    }

    ${(props) => props.theme.customBreakpoints[0]} {
      flex-wrap: wrap;
      justify-content: center;

      > .filter-menu {
        margin-right: 0;
        margin-bottom: 0.5rem;
      }
    }
  }

  .table-container {
    overflow: hidden;
    overflow-x: auto;
    border: 1px solid ${(props) => props.theme.colors.primary};
    width: 100%;

    .table {
      width: 100%;
      font-size: 0.875rem;
      min-width: 1400px;
      .table-head {
        background-color: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.white};

        th {
          padding: 0.75rem 1rem;
          text-transform: uppercase;
          line-height: 1.6;
          text-align: left;
        }
      }

      .table-body {
        tr {
          &:nth-of-type(even) {
            background-color: ${(props) => props.theme.colors.tableBgGray};
          }

          td {
            padding: 0.5rem 1rem;
            vertical-align: middle;
            line-height: 2.1;
          }
        }
        .red-text {
          color: ${(props) => props.theme.colors.secondary};
          font-weight: bold;
        }
        .green-text {
          color: ${(props) => props.theme.colors.green};
          font-weight: bold;
        }
        .black-text {
          color: ${(props) => props.theme.colors.black};
          font-weight: bold;
        }
      }
    }
  }
  
`;
export const NotFoundContainer = styled(Flex)`
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TradeInOldOffer = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(
    TradeIn_Status.STATUS_COUNTER_OFFER.value
  );
  const [statusText, setStatusText] = useState(
    TradeIn_Status.STATUS_COUNTER_OFFER.text
  );
  const [page, setPage] = useState(1);
  const isSmalMobilView = useMediaQuery("(max-width:416px)", true, false);
  const isTabMenuView = useMediaQuery("(min-width:1024px)", true, false);
  const { data, isLoading, refetch, isFetching } = useQuery(
    [status, page],
    getTradeInOrder,
    {
      keepPreviousData: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
    }
  );
  const { from, to, total, current_page, last_page } = data || {
    data: [],
  };

  const handleStatusChange = useCallback(
    (newStatus, newText) => {
      setPage(1);
      setStatus(newStatus);
      setStatusText(newText);
      refetch();
    },
    [setPage, setStatus, setStatusText, refetch]
  );

  return (
    <Container>
      <Flex
        sx={{
          flexDirection: ["column", "row"],
          justifyContent: "space-between",
          alignItems: ["flex-start", "center"],
        }}
      >
        <Flex>
          {isTabMenuView ? (
            OverviewTabMenuColumns?.map((item, index) => (
              <>
                <Text
                  key={index}
                  title={t(item.title)}
                  isActive={status === item.value}
                  onClick={() => handleStatusChange(item.value, item.status)}
                  sx={{
                    backgroundColor:
                      statusText === item.status
                        ? themes.colors.primary
                        : themes.colors.transparent,
                    color:
                      statusText === item.status
                        ? themes.colors.white
                        : themes.colors.buttonGray,
                    border:
                      statusText === item.status
                        ? `2px solid ${themes.colors.lightGray}`
                        : `2px solid ${themes.colors.buttonGray}`,
                    borderRadius: "0.2rem 0.2rem 0 0",
                    padding: "0.5em 0.4rem",
                    margin: "0.5rem 2px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {t(item.label)}
                  {statusText === item.status && (isLoading || isFetching) ? (
                    <Spinner
                      width="16px"
                      height="16px"
                      style={{
                        padding: "5px 0 0 5px",
                      }}
                      color={themes.colors.white}
                    />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      width="16px"
                      height="16px"
                      fill={themes.colors.white}
                      style={{
                        padding: "5px 0 0 5px",
                        display:
                          statusText === item.status ? "inline-block" : "none",
                      }}
                    >
                      <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160 352 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l111.5 0c0 0 0 0 0 0l.4 0c17.7 0 32-14.3 32-32l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1L16 432c0 17.7 14.3 32 32 32s32-14.3 32-32l0-35.1 17.6 17.5c0 0 0 0 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.8c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352l34.4 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L48.4 288c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" />
                    </svg>
                  )}
                </Text>
              </>
            ))
          ) : (
            <></>
          )}
        </Flex>
        <Box
          className={"pagination-container"}
          sx={{ width: !isTabMenuView ? "100%" : "auto" }}
        >
          <Flex
            sx={{
              width: !isTabMenuView ? "100%" : "auto",
              flexDirection: isSmalMobilView ? "column" : "row",
              alignItems: "space-between",
              justifyContent: "space-between",
            }}
          >
            {!isTabMenuView && (
              <Flex>
                <Menu
                  className="filter-menu"
                  showDropdownIcon={false}
                  label={
                    <Flex
                      sx={{
                        justifyContent: "space-between",
                        gap: ["2rem", "10rem"],
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ minWidth: "140px" }}>
                        <Text sx={{ display: "flex", alignItems: "center" }}>
                          {statusText === null || statusText === undefined
                            ? `${t("filter_by_status")}`
                            : `${t(statusText)}`}
                        </Text>
                      </Box>
                      <Box sx={{ mt: "0.5rem" }}>
                        <IconFilter
                          sx={{ marginLeft: "1rem", marginTop: "1rem" }}
                        />
                      </Box>
                    </Flex>
                  }
                  items={[
                    {
                      label: `${t("counter_offer")}`,
                      onClick: () =>
                        handleStatusChange(
                          TradeIn_Status.STATUS_COUNTER_OFFER.value,
                          TradeIn_Status.STATUS_COUNTER_OFFER.text
                        ),
                    },
                    {
                      label: `${t("under_review")}`,
                      onClick: () =>
                        handleStatusChange(
                          TradeIn_Status.STATUS_UNDER_REVIEW.value,
                          TradeIn_Status.STATUS_UNDER_REVIEW.text
                        ),
                    },
                    {
                      label: `${t("approved")}`,
                      onClick: () =>
                        handleStatusChange(
                          TradeIn_Status.STATUS_APPROVED.value,
                          TradeIn_Status.STATUS_APPROVED.text
                        ),
                    },
                    {
                      label: `${t("rejected")}`,
                      onClick: () =>
                        handleStatusChange(
                          TradeIn_Status.STATUS_REJECTED.value,
                          TradeIn_Status.STATUS_REJECTED.text
                        ),
                    },
                    {
                      label: `${t("lost_oppurtunities")}`,
                      onClick: () =>
                        handleStatusChange(
                          TradeIn_Status.STATUS_LOST_OPPURTUNITIES.value,
                          TradeIn_Status.STATUS_LOST_OPPURTUNITIES.text
                        ),
                    },
                  ]}
                />
              </Flex>
            )}
            <Box>
              <Pagination
                {...{ from, to, total, current_page, last_page }}
                onChange={(page) => {
                  setPage(page);
                }}
              />
            </Box>
          </Flex>
        </Box>
      </Flex>
      <StatusComponent
        data={data?.data}
        isLoading={isLoading}
        isFetching={isFetching}
        refetch={refetch}
        status={statusText}
        statusValue={status}
      />
    </Container>
  );
};

export default TradeInOldOffer;
