import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box, Button, Flex, Heading, Text } from "theme-ui";
import IconShoppingCart from "../../../components/icons/icon-shopping-cart";
import Container from "../../../components/Container";
import CartItem from "../../../components/CartItem";
import { useCartActions, useCartState } from "../../../contexts/CartContext";
import ShippingAddressSelect from "../Shop/ShippingAddressSelect";
import Money from "../../../components/Money";
import {
  createOrder,
  getOrderDetail,
  lockOrder,
  updateOrder,
} from "../../../apis";
import exceptionHandler from "../../../apis/exceptionHandler";
import { useMutation, useQuery, useQueryCache } from "react-query";
import WithLoader from "../../../components/WithLoader";
import { useModal } from "../../../contexts/ModalContext";
import { useHistory } from "react-router-dom";
import { useToast } from "../../../contexts/AlertProvider";
import { useTranslation } from "react-i18next";
import { getFormatedYukaPoints } from "../../../utils/helpers";
import COAProvider, { useCOAStatus } from "../../../contexts/COAProvider";
import IconAlert from "../../../components/icons/icon-alert";
import Logo from "../../../assets/images/YP.png";
import EditModeProvider, {
  useEditMode,
} from "../../../contexts/EditModeProvider";
import useMediaQuery from "../../../utils/useMediaQuery";
import CrossSellModal from "../../../components/CrossSellModal";

const CartContainer = styled(Box)`
  margin: 3rem 0;

  .page-header {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;

    .heading {
      display: inline-flex;
      align-items: center;
      padding-bottom: 0.5rem;
      color: ${(props) => props.theme.colors.primary};
      border-bottom: 3px solid ${(props) => props.theme.colors.secondary};
    }

    .svg-icon {
      margin-right: 0.5rem;
      height: 1.75rem;
      width: 1.75rem;
      fill: ${(props) => props.theme.colors.primary};
    }
  }

  .cart-list-container {
    margin-bottom: 1rem;
    max-height: calc(100vh - 28rem);
    min-height: 20rem;
    overflow: hidden;
    overflow-y: auto;

    .cart-header,
    .cart-item {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(12, 1fr);
      font-size: 1rem;
      padding: 0.75rem 1rem;
      border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
      align-items: center;

      .title {
        grid-column: span 5;
      }

      .counter-block {
        grid-column: span 3;
      }

      .amount {
        grid-column: span 2;
      }

      .amount {
        grid-column: span 2;
        justify-self: end;
      }

      &.cart-item-sm {
        .title {
          grid-column: span 6;
        }

        .counter-block {
          grid-column: span 6;
        }
      }
    }

    .cart-item {
      padding: 0.2rem 0.5rem;
    }

    .cart-header {
      position: sticky;
      top: 0;
      background-color: ${(props) => props.theme.colors.white};
      z-index: 10;
    }

    ${(props) => props.theme.customBreakpoints[0]} {
      max-height: none;

      .cart-item {
        padding: 1rem 0;
        grid-gap: 1rem;
        grid-template-columns: 1fr auto;

        .title {
          grid-row: 1;
          grid-column: 1;
        }

        .amount {
          grid-row: 2;
          grid-column: 1;
          justify-self: start;
        }

        .counter-block {
          grid-row: 1 / span 2;
          grid-column: 2;
          justify-self: end;
          align-self: center;

          .counter-input-container {
            width: 5.5rem;
          }
        }
      }

      .cart-header {
        display: none;
      }
    }
  }

  .text-primary {
    color: ${(props) => props.theme.colors.primary};
    margin: 0.3rem;
  }

  .text-secondary {
    color: ${(props) => props.theme.colors.secondary};
    margin: 0.3rem;
    cursor: pointer;
  }
`;

const LabelValueList = styled(Flex)`
  margin-bottom: 1rem;
  align-items: center;
  font-size: 1rem;

  .label {
    flex: 1 1 auto;
  }

  .value {
    flex: 0 1 20rem;
    text-align: right;

    .btn {
      width: 100%;
      max-width: 10rem;
    }
  }

  ${(props) => props.theme.customBreakpoints[0]} {
    flex-wrap: wrap;

    .label,
    .value {
      margin-bottom: 0.75rem;
    }

    .value {
      flex: 1 1 auto;
    }

    &.with-checkout-btn {
      .value {
        margin-top: 1rem;
        text-align: center;
      }
    }
  }
`;

const ConfirmationModal = styled(Box)`
  text-align: center;
  max-width: 24rem;

  .modal-title {
    color: ${(props) => props.theme.colors.primary};
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    text-transform: capitalize;

    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      height: 1.5rem;
      width: 1.5rem;
      fill: ${(props) => props.theme.colors.primary};
      margin-right: 0.5rem;
    }
  }

  .modal-body {
    margin-bottom: 2rem;
  }

  .modal-footer {
    justify-content: center;
    align-items: center;

    button {
      min-width: 10rem;
      margin: 0 1rem;
    }
  }
`;

const NoAccessMessage = styled(Box)`
  text-align: center;
  padding: 1.5rem 1rem;
  border-radius: 0.25rem;
  background-color: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;

  .svg-icon {
    margin-bottom: 0.75rem;
  }
`;
const Image = styled.img`
  height: 0.8rem;
  width: auto;
`;
const BoxFooterItem = styled.div`
  text-align: center;

  .title {
    font-weight: bold;
  }
`;

const TextAreaWithBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

const TextAreaBox = styled.div`
  grid-area: 1 / 1 / 2 / 3;

  textarea {
    width: 90%;
  }
`;

const BoxFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0.5rem 0;
  grid-area: 1 / 3 / 2 / 4;
`;

const Cart = () => {
  const { t } = useTranslation();
  const queryCache = useQueryCache();

  const {
    items,
    total_amount,
    total_reward_points,
    shipping_address_id,
    total_devices,
  } = useCartState();
  const history = useHistory();
  const toast = useToast();
  const isCOAEnabled = useCOAStatus();

  const {
    updateItem,
    removeItem,
    setShippingAddressId,
    clearCart,
  } = useCartActions();
  const { showModal } = useModal();
  const [editData] = useEditMode();
  const { orderId, mode } = editData || {};

  const [errors, setErrors] = useState([]);
  const [showShippingAddressError, setShowShippingAddressError] = useState(
    false
  );
  const [isReferenceError, setIsReferenceError] = useState(false);
  const [customerReference, setCustomerReference] = useState("");
  const isMobileView = useMediaQuery("(max-width: 575px)", true, false);
  const [isCrossSellConfirmed, setIsCrossSellConfirmed] = useState(false);
  const {
    data: orderDetailResponse,
    isLoading: isLoadingOrderDetail,
  } = useQuery(["order-detail", orderId, mode], getOrderDetail, {
    enabled: !!orderId,
    retry: false,
    onError: (err) => {
      exceptionHandler(err, (message) => {
        toast(message, { type: "error" });
        clearCart();
        history.push({
          pathname: "/account/my-orders",
          state: { orderId },
        });
      });
    },
  });

  const orderDetail = orderDetailResponse?.data;

  useEffect(() => {
    setCustomerReference(orderDetail?.customer_reference);
  }, [orderDetail?.customer_reference]);

  useQuery(["order-lock", orderId], lockOrder, {
    enabled: !!orderDetail,
    staleTime: 0,
    refetchInterval: 15 * 1000,
    retry: false,
    onError: (err) => {
      if (err.response && err.response.status === 403) {
        toast("Update timeout.", { type: "error" });
      } else {
        exceptionHandler(err, (message) => {
          toast(message, { type: "error" });
        });
      }

      clearCart();
      history.push({
        pathname: "/account/my-orders",
        state: { orderId },
      });
    },
  });

  const [createOrderMutate, { isLoading }] = useMutation(createOrder, {
    onSuccess: () => {
      clearCart();
      queryCache.refetchQueries(["stock-list"]);
      queryCache.refetchQueries(["orders"]);
      showModal(({ hideModal }) => {
        return (
          <ConfirmationModal>
            <Heading className="modal-title">{t("congratulations")}</Heading>
            <Box className="modal-body">
              <Text>{t("your_orders_have_been_processed")}</Text>
            </Box>
            <Flex className="modal-footer">
              <Button
                variant={"secondary"}
                onClick={() => {
                  hideModal();
                  history.push("/shop");
                }}
              >
                {t("continue_shopping")}
              </Button>
            </Flex>
          </ConfirmationModal>
        );
      });
    },
    onError: (error) => {
      exceptionHandler(
        error,
        (error) => {
          toast(error, { type: "error" });
        },
        (errors) => {
          queryCache.invalidateQueries("stock-list").then(() => {
            errors.forEach((item) => {
              if (Boolean(item?.artnr) && Boolean(item?.max_quantity)) {
                updateItem(item.artnr, {
                  max_quantity: item.max_quantity,
                  quantity: item.max_quantity,
                });
              }
            });
            setErrors(errors);
            toast(t("the_given_data_is_invalid"), {
              type: "error",
            });
          });
        }
      );
    },
  });

  const [updateOrderMutate, { isLoading: isUpdatingOrder }] = useMutation(
    updateOrder,
    {
      onSuccess: () => {
        toast(t("order_updated_successfully"), { type: "success" });
        queryCache.refetchQueries(["order-detail", orderId, mode]);
        clearCart();
        history.push({
          pathname: "/account/my-orders",
          state: { orderId: orderId, previousDetail: orderDetail },
        });
      },
      onError: (error) => {
        exceptionHandler(
          error,
          (error) => {
            toast(error, { type: "error" });
            clearCart();
            history.push({
              pathname: "/account/my-orders",
              state: { orderId },
            });
          },
          (errors) => {
            queryCache.invalidateQueries("stock-list").then(() => {
              errors.forEach((item) => {
                if (Boolean(item?.artnr) && Boolean(item?.max_quantity)) {
                  updateItem(item.artnr, {
                    max_quantity: item.max_quantity,
                    quantity: item.max_quantity,
                  });
                }
              });
              setErrors(errors);
              toast(t("the_given_data_is_invalid"), {
                type: "error",
              });
            });
          }
        );
      },
    }
  );

  const onCheckoutConfirmation = useCallback(() => {
    if (orderId) {
      updateOrderMutate({
        id: orderId,
        data: {
          items: items.map((item) => ({
            article_number: item["article_no"],
            requested_stock: item["quantity"],
            csell: item.csell,
          })),
          customer_address_id: shipping_address_id,
          customer_reference: customerReference,
        },
      });
    } else {
      createOrderMutate({
        items: items.map(({ article_no, quantity, csell }) => ({
          article_number: article_no,
          requested_stock: quantity,
          csell,
        })),
        customer_address_id: shipping_address_id,
        customer_reference: customerReference,
      });
    }
  }, [
    createOrderMutate,
    customerReference,
    items,
    orderId,
    shipping_address_id,
    updateOrderMutate,
  ]);

  const onCheckout = useCallback(() => {
    if (shipping_address_id === -1) {
      setShowShippingAddressError(true);
      return;
    }
    if (isMobileView) {
      showModal(({ hideModal }) => {
        return (
          <ConfirmationModal>
            <Heading className="modal-title">{t("confirm")}</Heading>
            <Box className="modal-body">
              <Text>{t("do_you_want_to_confirm_your_order")}</Text>
            </Box>
            <Flex className="modal-footer">
              <Button
                variant={"primary"}
                onClick={() => {
                  onCheckoutConfirmation();
                  hideModal();
                }}
              >
                {t("yes")}
              </Button>
              <Button
                variant={"secondary"}
                onClick={() => {
                  hideModal();
                }}
              >
                {t("go_back")}
              </Button>
            </Flex>
          </ConfirmationModal>
        );
      });
    } else {
      if (isCrossSellConfirmed) {
        showModal(({ hideModal }) => {
          return (
            <ConfirmationModal>
              <Heading className="modal-title">{t("confirm")}</Heading>
              <Box className="modal-body">
                <Text>{t("do_you_want_to_confirm_your_order")}</Text>
              </Box>
              <Flex className="modal-footer">
                <Button
                  variant={"primary"}
                  onClick={() => {
                    onCheckoutConfirmation();
                    hideModal();
                  }}
                >
                  {t("yes")}
                </Button>
                <Button
                  variant={"secondary"}
                  onClick={() => {
                    hideModal();
                  }}
                >
                  {t("go_back")}
                </Button>
              </Flex>
            </ConfirmationModal>
          );
        });
      } else {
        showModal(({ hideModal }) => {
          return (
            <COAProvider>
              <EditModeProvider>
                <CrossSellModal
                  setIsCrossSellConfirmed={setIsCrossSellConfirmed}
                  hideModals={hideModal}
                  onCheckoutConfirmation={onCheckoutConfirmation}
                ></CrossSellModal>
              </EditModeProvider>
            </COAProvider>
          );
        });
      }
    }
  }, [
    shipping_address_id,
    isMobileView,
    showModal,
    t,
    onCheckoutConfirmation,
    isCrossSellConfirmed,
  ]);

  if (!isCOAEnabled) {
    //@todo coa no access design here
    return (
      <NoAccessMessage>
        <IconAlert height="3rem" width="3rem" fill="currentColor" />
        <Text>{t("unconfirmed_coa_alert")}</Text>
      </NoAccessMessage>
    );
  }

  if (items.length === 0)
    return (
      <CartContainer>
        <Container>
          <Box className="page-header">
            <Heading className="heading">
              <IconShoppingCart /> {t("your_order")}
            </Heading>
          </Box>
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
              minHeight: "23.25rem",
              textAlign: "center",
              padding: "2.5rem 0",
            }}
          >
            <Heading sx={{ fontWeight: 400 }}>
              {t(
                "you_havent_added_anything_to_our_order_list_yet_start_shopping_now"
              )}
            </Heading>
            <Button
              variant="secondary"
              sx={{ minWidth: "10rem" }}
              onClick={() => {
                history.push("/shop");
              }}
            >
              {t("shop")}
            </Button>
            <Box>
              <Heading sx={{ lineHeight: "1.5" }}>
                {t("need_help")}
                <span className="text-primary">{t("call_us_at")}</span>
                <span
                  className="text-secondary"
                  onClick={() => window.open("tel:+49 (0) 69 83 83 25 0")}
                >
                  +49 (0) 69 83 83 25 0
                </span>
                <span className="text-primary">{t("or_contact_us_by")}</span>
                <span
                  className="text-secondary"
                  onClick={() => window.open("mailto:info@yukatel.de")}
                >
                  {t("email")}
                </span>
              </Heading>
            </Box>
          </Flex>
        </Container>
      </CartContainer>
    );
  return (
    <CartContainer>
      <Container>
        <Box className="page-header">
          <Heading className="heading">
            <IconShoppingCart /> {t("your_order")}
          </Heading>
        </Box>
        <Box className="cart-list-container">
          <Box className="cart-header">
            <Heading className="title">{t("item_description")}</Heading>
            <Heading className="counter-block">{t("quantity")}</Heading>
            <Heading className="amount">{t("cost")}</Heading>
            <Heading className="amount">{t("cart_total_cost")}</Heading>
          </Box>
          {items?.map(
            (
              {
                article_no,
                ean,
                description,
                selling_price,
                quantity,
                max_quantity,
                reward_points,
              },
              key
            ) => {
              return (
                // TODO: After the screen size is below 575px isSmall should be set {true}
                <CartItem
                  article_no={article_no}
                  ean={ean}
                  isSmall={isMobileView}
                  keyForData={article_no}
                  key={key}
                  description={description}
                  selling_price={selling_price}
                  quantity={quantity}
                  max_quantity={max_quantity}
                  onChange={(quantity) => {
                    updateItem(article_no, {
                      article_no,
                      description,
                      selling_price,
                      reward_points,
                      quantity,
                      max_quantity,
                      total_amount: quantity * selling_price,
                      total_reward_points: quantity * reward_points,
                    });
                  }}
                  onRemove={() => {
                    removeItem(article_no);
                  }}
                  error={errors.find((item) => item.artnr === article_no)}
                  ind={key}
                />
              );
            }
          )}
        </Box>
        <TextAreaWithBox>
          <TextAreaBox>
            <Box className="label">{t("your_reference")}</Box>
            <textarea
              rows="4"
              value={customerReference}
              onChange={(e) => {
                if (e.target.value.length > 20) {
                  setIsReferenceError(true);
                } else {
                  setIsReferenceError(false);
                }
                setCustomerReference(e.target.value);
              }}
            />
            {isReferenceError && (
              <Box className="label">
                <Text
                  sx={{
                    fontStyle: "italic",
                    fontSize: "0.675rem",
                    color: "red",
                  }}
                >
                  *{t("your_reference_contains_more")}.
                </Text>
              </Box>
            )}
          </TextAreaBox>
          <BoxFooter>
            <BoxFooterItem>
              <h5 className="title">{t("cart_total_devices")}</h5>
              {total_devices} pcs.
            </BoxFooterItem>

            <BoxFooterItem>
              <h5 className="title">{t("accumulated_yuka_points")} </h5>
              {getFormatedYukaPoints(total_reward_points)} <Image src={Logo} />
            </BoxFooterItem>
            <BoxFooterItem>
              <h5 className="title"> {t("total_before_taxes")} </h5>
              <Money amount={total_amount} />
            </BoxFooterItem>
          </BoxFooter>
        </TextAreaWithBox>

        <LabelValueList>
          <Box className="label">{t("shipping_address")}</Box>
          <Box className="value">
            <ShippingAddressSelect
              hasDefaultOption={false}
              value={shipping_address_id}
              onChange={(value) => {
                setShowShippingAddressError(value === -1);
                setShippingAddressId(value);
              }}
            />
            {showShippingAddressError && (
              <Box className="label">
                <Text
                  sx={{
                    fontStyle: "italic",
                    fontSize: "0.875rem",
                    color: "red",
                  }}
                >
                  *{t("shipping_address_is_required")}.
                </Text>
              </Box>
            )}
          </Box>
        </LabelValueList>

        <LabelValueList className="with-checkout-btn">
          <Box className="label">
            <Text sx={{ fontStyle: "italic", fontSize: "0.875rem" }}>
              *{" "}
              {t(
                "amount_may_be_subjected_to_insurance_shipping_charge_and_taxes"
              )}
              . {t("cart_item_will_remain_up_to_8_hour")}
            </Text>
          </Box>
          <Text className="value">
            <WithLoader isLoading={isLoading || isUpdatingOrder}>
              <Button
                variant="secondary"
                className="btn"
                onClick={onCheckout}
                disabled={isLoadingOrderDetail || isReferenceError}
              >
                {orderId ? t("update_order") : t("checkout")}
              </Button>
            </WithLoader>
          </Text>
        </LabelValueList>
      </Container>
    </CartContainer>
  );
};

export default Cart;
