import React, { useEffect, useRef, useState, useCallback } from "react";
import io from "socket.io-client";
import { SOCKET_URL, SocketConnetionType } from "../constants";
import { getItem } from "./storage";

let chatOpen = false;
const useSocket = (setUnreadCount, isChatOpen, setLastMessage) => {
  const token = getItem("chat_token");
  const chatUsername = getItem("chat_username");
  const [chatId, setChatId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [lastActiveAt, setLastActiveAt] = useState(null);
  const [loadConversations, setLoadConversations] = useState(null);
  const chatBodyRef = useRef(null);
  const socketRef = useRef(null);

  useEffect(() => {
    chatOpen = isChatOpen;
    if (!socketRef.current) {
      socketRef.current = io(SOCKET_URL, {
        transports: ["websocket"],
        reconnection: true,
        multiplex: false,
        withCredentials: true,
        query: {},
        path: "/chat-app/socket",
        extraHeaders: {
          Authorization: `${token}`,
        },
        auth: {
          authorizationToken: `${token}`,
        },
      });

      socketRef.current.on(SocketConnetionType.CONNECTION, () => {
        console.log("Connected to socket");
      });
      socketRef.current.on(SocketConnetionType.LOAD_CONVERSATIONS, (data) => {
        setLoadConversations(data);
        setChatId(data[0]?.conversationId);
      });

      socketRef.current.on(SocketConnetionType.LAST_ONLINE_TIME, (data) => {
        setLastActiveAt(data?.lastActiveAt);
      });
      socketRef.current.on("disconnect", () => {
        leaveConversation();
      });
      socketRef.current.on(SocketConnetionType.MESSAGE, (newMsg) => {
        const isSentByUser = newMsg.senderName === chatUsername;
        if (!chatOpen) {
          setUnreadCount((prevCount) => prevCount + 1);
        }
        setMessages((prev) => {
          const messageIndex = prev.findIndex(
            (msg) =>
              msg.sentAt === newMsg.sentAt && msg.content === newMsg.content
          );
          if (messageIndex === -1) {
            if (!isSentByUser) {
              setLastMessage(newMsg.content);
            }
            return [...prev, newMsg];
          }
          return prev;
        });

        if (chatBodyRef.current) {
          chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
      });
    }
  }, [token, lastActiveAt, setUnreadCount, isChatOpen, setLastMessage]);

  const sendMessage = (content) => {
    if (socketRef.current) {
      socketRef.current.emit(
        SocketConnetionType.MESSAGE,
        JSON.stringify(content)
      );
    }
  };

  const joinConversation = () => {
    if (socketRef.current.connected) {
      socketRef.current.emit(SocketConnetionType.JOIN_CONVERSATION);
    }
  };

  const leaveConversation = () => {
    if (!socketRef) return;

    if (socketRef.current.connected) {
      socketRef.current.emit(SocketConnetionType.LEAVE_CONVERSATION);
    }
  };

  return {
    sendMessage,
    chatId,
    messages,
    setMessages,
    chatBodyRef,
    joinConversation,
    leaveConversation,
    lastActiveAt,
    setLastActiveAt,
    loadConversations,
  };
};

export default useSocket;
