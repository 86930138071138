import { darken, transparentize } from "@theme-ui/color";

export default {
  colors: {
    // Theme Colors
    primary: "#053668",
    secondary: "#D2382B",
    primaryLight: "rgba(5,54,104,0.12)",
    grey: "#00000029",
    fontColor: "#2e2e2e",
    darkGrey: "#2E2E2EA3",
    opaqueBlack: "rgba(0,0,0,0.4)",
    inputBG: "#f8f9fa",
    lightGreen: "#90EE90",
    transparent: "transparent",
    yellow: "#ffde09",
    borderGray: "#878787",
    messageColor: "#e0e0e0  ",
    green: "#32d648",
    lightTableGray: "#f2f2f2",
    tableLightHeaderGray: "#F3F3F3",
    buttonGray: "#757575",
    tableHeaderDarkGray: "#414141",
    lightButtonGray: "#CBD5E0",
    tableHeaderGray: "#cdcdcd",
    headerBlue: "#09396a",
    tableHeaderTextGray: "#585858",
    modalTableHeaderGray: "#888888",
    black: "black",
    // Accent Colots
    warning: "#FFD23D",
    danger: "#E20A16",
    success: "#2ed645",
    info: "#1e9cd8",

    // Others
    primaryText: "#141414",
    secondaryText: "#666666",
    gray: "#dcdcdc",
    darkGray: "b8b8b8",
    borderDarkGray: "#707070",
    black: "#000000",
    lightBlack: "#0000004f",
    lightGray: "lightgray",
    white: "#FFFFFF",
    snowWhite: "#f7f8fa",
    highlight: "",
    darkblue: "#00008b",
    dark: "#333333",
    red: "red",
    alertRed: "#ff3d3d",
    tableBgGray: "#dcdcdd",
    titleColor: "#26304F",
    cartTDBG: "rgba(85,131,177,0.22)",
    cartTHColor: "#A7AFB7",
    cartTDColor: "#212529",
    buttonBG: "#00CC83",
    buttonColor: "#FFFFFF",
    searchBorderBG: "#999999",
    stepFourInputBorder: "#1C5099",
    stepFourInputColor: "rgba(38,48,79,0.57)",
    tradeInHomeButtonBG: "#E20A16",
    tradeInHomeSectionBG: "rgb(4,32,51)",
    tradeInHomeSectionGradient:
      "linear-gradient(90deg, rgba(4,32,51,1) 0%, rgba(7,39,61,1) 20%, rgba(16,64,94,1) 100%)",
    tradeInCartErrorBG: "#e5baba",
    directBuyProductBG: "#D5FFE4",
    tradeInCartBG: "#E5E5E5",
    searchBG: "#F5F5F7",
    productTableInputBorder: "#999999",
    productTableInputColor: "#1D1D1F",
    border_color: "#CACED5",
    side_bar_bg: "#F7F8FA",
    lightBlue: "#89CFF0",
    grayTextColor: "#acacac",
    lightCoral: "lightcoral",
  },
  customBreakpoints: [
    "@media (max-width: 575px)",
    "@media (max-width: 768px)",
    "@media (max-width: 992px)",
    "@media (max-width: 1200px)",
  ],
  space: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80],
  fonts: {
    body: '"Roboto", sans-serif',
    heading: '"Raleway", sans-serif',
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
    heading: 600,
  },
  text: {
    heading: {
      fontFamily: "heading",
      lineHeight: "1.2",
      fontWeight: "heading",
    },
    error: {
      fontFamily: "body",
      fontWeight: "bold",
      color: "danger",
    },
  },
  styles: {
    a: {
      color: "primaryText",
      textDecoration: "none",
      "&:hover": {
        color: "primary",
        textDecoration: "underline",
      },
    },
    root: {
      fontFamily: "body",
      lineHeight: "1.5",
      color: "primaryText",
    },
    h1: {
      variant: "text.heading",
      fontSize: "2rem",
    },
    h2: {
      variant: "text.heading",
      fontSize: "2rem",
    },
    h3: {
      variant: "text.heading",
      fontSize: "1.75rem",
    },
    h4: {
      variant: "text.heading",
      fontSize: "1.5rem",
    },
    h5: {
      variant: "text.heading",
      fontSize: "1.25rem",
    },
    h6: {
      variant: "text.heading",
      fontSize: "1rem",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    sectionHeading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
    },
    hr: {
      borderColor: "border",
    },
  },
  shadows: {
    default: "0px 1px 6px rgba(0,0,0,0.12)",
    card: "0px 1px 6px rgba(0,0,0,0.12)",
    dropDown: "0px 2px 12px rgba(0,0,0,0.24)",
  },
  links: {
    normal: {
      fontWeight: "normal",
    },

    secondary: {
      color: "danger",
      textDecoration: "none",
    },

    nav: {
      textDecoration: "none",
      fontSize: "0.875rem",
    },
    tab: {
      fontWeight: "medium",
      color: "inherit",
      textDecoration: "none",
      fontSize: "1rem",
      padding: "0.75rem 1rem",
      marginRight: "2rem",
      borderBottom: "2px solid transparent",
      "&:hover": {
        color: "danger",
      },
      "&.active": {
        borderColor: "danger",
      },
    },
  },

  buttons: {
    primary: {
      backgroundColor: "primary",
      fontSize: "0.875rem",
      lineHeight: 1.6,
      padding: "0.5rem 1rem",
      textTransform: "uppercase",
      borderRadius: "2rem",
      border: "1px solid",
      borderColor: "primary",
      "&:hover:enabled": {
        backgroundColor: darken("primary", 0.08),
        borderColor: darken("primary", 0.08),
      },
      "&:disabled": {
        opacity: "0.5",
        cursor: "not-allowed",
      },
    },
    secondary: {
      backgroundColor: "danger",
      fontSize: "0.875rem",
      lineHeight: 1.6,
      padding: "0.5rem 1rem",
      textTransform: "uppercase",
      borderRadius: "2rem",
      border: "1px solid",
      borderColor: "danger",
      "&:hover:enabled": {
        backgroundColor: darken("danger", 0.12),
        borderColor: darken("danger", 0.12),
      },
      "&:disabled": {
        opacity: "0.5",
        cursor: "not-allowed",
      },
    },
    primaryBlock: {
      backgroundColor: "primary",
      fontSize: "0.875rem",
      lineHeight: 1.6,
      padding: "0.5rem 1rem",
      textTransform: "uppercase",
      display: "block",
      width: "100%",
      borderRadius: "2rem",
      border: "1px solid",
      borderColor: "primary",
      "&:hover:enabled": {
        ackgroundColor: darken("primary", 0.08),
        borderColor: darken("primary", 0.08),
      },
      "&:disabled": {
        opacity: "0.5",
        cursor: "not-allowed",
      },
    },
    primaryOutline: {
      backgroundColor: "white",
      fontSize: "0.875rem",
      lineHeight: 1.6,
      padding: "0.5rem 1rem",
      textTransform: "uppercase",
      border: "1px solid",
      borderColor: "primary",
      color: "primary",
      cursor: "pointer",
      borderRadius: "2rem",

      "&:hover:enabled": {
        backgroundColor: "primary",
        color: "white",
      },
      "&:disabled": {
        opacity: "0.5",
        cursor: "not-allowed",
      },
    },
    secondaryOutline: {
      backgroundColor: "white",
      fontSize: "0.875rem",
      lineHeight: 1.6,
      padding: "0.5rem 1rem",
      textTransform: "uppercase",
      border: "1px solid",
      borderColor: "danger",
      color: "danger",
      cursor: "pointer",
      borderRadius: "2rem",
      fill: "danger",

      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      ".svg-icon": {
        marginRight: "0.5rem",
        height: "18px",
        width: "18px",
      },

      "&:hover:enabled": {
        backgroundColor: "danger",
        color: "white",
        fill: "white",
      },

      "&:disabled": {
        opacity: "0.5",
        cursor: "not-allowed",
      },
    },
    iconButton: {
      borderRadius: "50%",
      padding: "0",
      height: "2.5rem",
      width: "2.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "transparent",
      "&:hover:enabled": {
        backgroundColor: transparentize("primaryText", 0.92),
      },
      "&:disabled": {
        opacity: "0.5",
        cursor: "not-allowed",
      },
    },
    primaryIconButton: {
      borderRadius: "50%",
      padding: "0",
      height: "2.5rem",
      width: "2.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "primary",
      fill: "white",
      "&:hover:enabled": {
        backgroundColor: darken("primary", 0.08),
      },
      "&:disabled": {
        opacity: "0.5",
        cursor: "not-allowed",
      },
    },
    secondaryIconButton: {
      borderRadius: "50%",
      padding: "0",
      height: "2.5rem",
      width: "2.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "danger",
      fill: "white",
      "&:hover:enabled": {
        backgroundColor: darken("danger", 0.08),
      },
      "&:disabled": {
        opacity: "0.5",
        cursor: "not-allowed",
      },
    },
  },

  badges: {
    success: {
      fontSize: "0.875rem",
      lineHeight: 1.6,
      padding: "0.5rem 1rem",
      textTransform: "uppercase",
      borderRadius: "2rem",
      fontWeight: "400",
      color: "primaryText",
      bg: "success",
    },
    info: {
      fontSize: "0.875rem",
      lineHeight: 1.6,
      padding: "0.5rem 1rem",
      textTransform: "uppercase",
      borderRadius: "2rem",
      fontWeight: "400",
      color: "primaryText",
      bg: "info",
    },
    warning: {
      fontSize: "0.875rem",
      lineHeight: 1.6,
      padding: "0.5rem 1rem",
      textTransform: "uppercase",
      borderRadius: "2rem",
      fontWeight: "400",
      color: "primaryText",
      bg: "warning",
    },
    danger: {
      fontSize: "0.875rem",
      lineHeight: 1.6,
      padding: "0.5rem 1rem",
      textTransform: "uppercase",
      borderRadius: "2rem",
      fontWeight: "400",
      color: "white",
      bg: "danger",
    },
    partial: {
      fontSize: "0.875rem",
      lineHeight: 1.6,
      padding: "0.5rem 1rem",
      textTransform: "uppercase",
      borderRadius: "2rem",
      fontWeight: "400",
      color: "primaryText",
      backgroundImage:
        "linear-gradient(to right, #2ed645 0%, #2ed645 50%, #FFD23D 50%, #FFD23D 100%)",
    },
    cartBadge: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "1.5rem",
      width: "1.5rem",
      fontSize: "0.75rem",
      borderRadius: "50%",
      fontWeight: "600",
      color: "white",
      bg: "danger",
      position: "absolute",
      top: "0.5rem",
      right: "0.5rem",
      padding: 0,
      lineHeight: 1,
    },
  },

  forms: {
    checkbox: {
      fill: "danger",
    },
    radio: {
      fill: "danger",
    },
    select: {
      padding: "0.5rem 1rem",
      lineHeight: "1.6",
      backgroundColor: "lightGray",
      borderColor: "lightGray",

      "&:focus": {
        outline: "none",
        borderColor: "primary",
      },
    },
  },
  alerts: {
    primary: {
      color: "white",
      bg: "primary",
    },
    muted: {
      color: "primaryText",
      bg: "muted",
    },
    danger: {
      color: "white",
      bg: "danger",
    },
    warning: {
      color: "white",
      bg: "warning",
    },
  },
  cards: {
    default: {
      padding: "1.25rem",
      borderRadius: "0.25rem",
      boxShadow: "0 1px 4px rgba(0, 0, 0, 0.125), 1px 4px rgba(0, 0, 0, 0.075)",
    },
    bordered: {
      padding: "1.25rem",
      borderRadius: "0.25rem",
      border: "1px solid",
      borderColor: "lightGray",
    },
  },
  border: {
    default_border: `1px solid #CACED5`,
  },
};
