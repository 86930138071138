/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import "../style.css";
import themes from "../themes";

const Timer = ({ dateStr }) => {
  const [remainingTime, setRemainingTime] = useState(dateStr);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prev) => prev - 1000);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [dateStr]);

  const format = useMemo(() => {
    const utcToDate = new Date(remainingTime);
    const hours = utcToDate.getUTCHours().toString().padStart(2, "0");
    const minutes = utcToDate.getUTCMinutes().toString().padStart(2, "0");
    const seconds = utcToDate.getUTCSeconds().toString().padStart(2, "0");
    return {
      hours,
      minutes,
      seconds,
    };
  }, [remainingTime]);

  return (
    <div className="countdown">
      <div className="content">
        <div className="box">
          <span
            style={{
              color: themes.colors.primary,
              fontWeight: "bold",
              fontSize: "12px",
              height: "23px",
            }}
          >
            {"hh"}
          </span>
          <div className="value">
            <span>{format?.hours}</span>
          </div>
        </div>
        <div className="box">
          <span
            style={{
              color: themes.colors.primary,
              fontWeight: "bold",
              fontSize: "12px",
              height: "23px",
            }}
          >
            {"mm"}
          </span>
          <div className="value">
            <span>{format?.minutes}</span>
          </div>
        </div>
        <div className="box">
        <span
            style={{
              color: themes.colors.primary,
              fontWeight: "bold",
              fontSize: "12px",
              height: "23px",
            }}
          >
            {"ss"}
          </span>
          <div className="value">
            <span>{format?.seconds}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timer;
