import React from "react";
import themes from "../../../themes";
import { Flex, Button, Heading } from "theme-ui";
import IconShoppingCart from "../../icons/icon-shopping-cart";
import { useTradeInCartContext } from "../../../contexts/TradeInCartContext";
import IconClose from "../../icons/icon-close";
import Card from "../../Card";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import AddProductCard from "./AddProductCard";
import PropTypes from "prop-types";

const MobileProductCartContainer = styled.div`
  .drawer-wrapper {
    height: 100%;
    display: flex;
    align-items: flex-start;
    transition: all 0.4s ease;

    .outside-click {
      display: none;
      flex: 1 1 auto;
      background: transparent;
    }

    .header {
      .icon-btn {
        display: none;
      }
    }

    &.drawer-cart {
      position: fixed;
      top: 0;
      bottom: 0;
      right: -60%;
      z-index: 100;
      width: 70%;
      align-items: stretch;

      .outside-click {
        display: block;
      }

      &.opened {
        right: 0;
      }
      .cart-table {
        width: 100%;
        height: 100%;
        font-size: 0.875rem;
        border-radius: 10px;
        padding-left: 1em;

        .cart-table-body {
          width: 100%;
          height: 100%;
          tr {
            height: 7.2em;

            background-color: ${(props) => props.theme.colors.white};
            margin: 0.5em;

            td {
              min-width: 1.5;
              padding: 0.1rem 0.2rem;
              vertical-align: middle;
              color: ${(props) => props.theme.colors.titleColor};
              align-items: center;
            }
            @media screen and (max-width: 350px) {
              height: 12em;
            }
          }
        }
      }
      @media screen and (max-width: 512px) {
        & {
          position: fixed;
          top: 0;
          bottom: 0;
          right: -100%;
          width: 100%;
          align-items: stretch;
        }
      }
    }
  }
`;

const MobileProductCart = ({
  cartProducts,
  errProducts,
  setCartProduct,
  onModalOpen,
  handleRemove,
  handleStepAlertModal,
  handleNextStep,
  windowHeight,
  windowWidth,
}) => {
  const { t } = useTranslation();
  const { isOpen, closeModal } = useTradeInCartContext();

  return (
    <MobileProductCartContainer>
      <Flex
        className={`drawer-wrapper drawer-cart ${isOpen ? "opened" : ""}`}
        sx={{ height: `${windowHeight}px` }}
      >
        <Card>
          <Flex
            sx={{
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBottom: "1em",
              borderBottom: `1px solid ${themes.colors.primary}`,
            }}
          >
            <IconShoppingCart />
            <Heading>
              {t("step_three_product_cart_title")} ({cartProducts?.length})
            </Heading>
            <Button
              variant="secondaryIconButton"
              className="icon-btn"
              onClick={closeModal}
            >
              <IconClose />
            </Button>
          </Flex>

          <AddProductCard
            data={cartProducts}
            errProducts={errProducts}
            setData={setCartProduct}
            onModalOpen={onModalOpen}
            handleStepAlertModal={handleStepAlertModal}
            nextStep={handleNextStep}
            handleRemove={handleRemove}
            windowWidth={windowWidth}
          />

          <Flex
            style={{
              minWidth: "3rem",
              display: "flex",
              flexDirection: ["row", "row", "row", "column"],
              justifyContent: "flex-end",
              borderTop: `1px solid ${themes.colors.primary}`,
              padding: "1rem 0.5rem",
            }}
          >
            <Button
              type="button"
              onClick={handleNextStep}
              sx={{
                minWidth: "3rem",
                borderRadius: "3px",
              }}
            >
              {t("step_next")}
            </Button>
          </Flex>
        </Card>
      </Flex>
    </MobileProductCartContainer>
  );
};
MobileProductCart.propTypes = {
  cartProducts: PropTypes.object,
  errProducts: PropTypes.object,
  setCartProduct: PropTypes.func,
  onModalOpen: PropTypes.func,
  handleStepAlertModal: PropTypes.func,
  handleNextStep: PropTypes.func,
};
export default MobileProductCart;
