import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { Box, Flex, Input } from "theme-ui";
import themes from "../../themes";
import { roundToInteger } from "../../utils/helpers";
import Tooltip from "../Tooltip";

const InputGroup = ({
  placeHolder,
  icon,
  rightText,
  type,
  value,
  disabled,
  max,
  min,
  leftInputStyle,
  rightInputStyle,
  toolTipMessage,
  toolTipStyle,
  name,
  onChange,
  errorInputs,
  id,
  focusId,
  ...props
}) => {
  const handleChange = useCallback(
    (value) => {
      if (type === "number" && parseFloat(value) < 0) {
        value = Math.abs(value);
      } else{
        value = roundToInteger(value.replace(",", "."));
      }
      onChange(id, name, value);
    },
    [id, name, onChange]
  );

  return (
    <Flex sx={{ width: "100%", flexDirection: "column", height: "100%" }}>
      <Flex
        hasIcon={Boolean(icon)}
        className={
          (errorInputs?.[id] && "input-container w-100 has-error") ||
          "input-container"
        }
        sx={{
          width: "100px",
          height: "34px",
          borderRadius: "3px",
          border:
            (errorInputs?.[id] && `0.3px solid ${themes.colors.danger}`) ||
            `0.3px solid ${themes.colors.productTableInputBorder}`,
        }}
      >
        <Flex
          columns={2}
          sx={{
            backgroundColor: disabled
              ? themes.colors.transparent
              : `${themes.colors.white} !important`,
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            height: "31px",
            borderRadius: "0.2rem",
            fontWeight: "bold",
          }}
        >
          {icon && <Box className="input-icon">{icon}</Box>}
          <Input
            className="input"
            type={type}
            autoComplete="off"
            placeholder={placeHolder}
            value={value}
            {...props}
            id={id}
            max={max}
            min={min}
            style={{
              padding: "8px 3px 8px 4px",
              height: "100%",
              backgroundColor: disabled
                ? themes.colors.tradeInCartBG
                : themes.colors.white,
              width: "40px",
              border: 0,
              fontWeight: "bold",
              fontSize: "0.75em",
              ...leftInputStyle,
            }}
            onChange={(e) => handleChange(e.target.value)}
            disabled={disabled}
          />
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: "0.1em",
              width: "calc(100% - 40px)",
              fontWeight: 500,
              borderLeft: `0.3px solid ${themes.colors.lightGray}`,
              color: themes.colors.primary,
              backgroundColor: themes.colors.lightGray,
              ...rightInputStyle,
              ...leftInputStyle,
            }}
          >
            {rightText}
          </Flex>
        </Flex>
      </Flex>
      <Flex>
        {focusId === id && (
          <Tooltip message={toolTipMessage} style={toolTipStyle} />
        )}
      </Flex>
    </Flex>
  );
};

InputGroup.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.element,
  iconPlacement: PropTypes.oneOf(["prefix", "suffix"]),
  error: PropTypes.string,
  type: PropTypes.string,
  rightText: PropTypes.string,
  placeHolder: PropTypes.string,
  toolTipMessage: PropTypes.string,
  toolTipStyle: PropTypes.object,
  leftInputStyle: PropTypes.object,
  rightInputStyle: PropTypes.object,
  max: PropTypes.number,
  min: PropTypes.number,
  disabled: PropTypes.bool,
  directBuyPrice: PropTypes.number,
  value: PropTypes.object,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

export default InputGroup;
