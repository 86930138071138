import httpClient from "./httpClient";
import convertJsonToFormData from "../utils/convertJsonToFormData";
import { getItem, removeItem } from "../utils/storage";
import i18n from "../utils/i18n";
import { CHAT_API_URL } from "../constants";

const httpClientInstance = httpClient({
  transformRequest: [
    (data, headers) => {
      headers["Accept"] = "application/json";
      headers["Content-Type"] = "application/json";
      headers["locale"] = i18n.language;

      return JSON.stringify(data);
    },
  ],
});

const httpChatInstance = httpClient({
  baseURL: CHAT_API_URL,
  transformRequest: [
    (data, headers) => {
      headers["Accept"] = "application/json";
      headers["Content-Type"] = "application/json";
      headers["Authorization"] = `${getItem("chat_token")}`;
      headers["locale"] = i18n.language;

      return JSON.stringify(data);
    },
  ],
  validateStatus: function (status) {
    if (status === 401) {
      removeItem("token");
      removeItem("cart");
      window.location.reload();
    }
    return status >= 200 && status < 300;
  },
});

export const httpClientMultipartInstance = httpClient({
  transformRequest: [
    (data, headers) => {
      headers["Accept"] = "application/json";
      headers["Content-Type"] = "multipart/form-data";
      headers["locale"] = i18n.language;

      return convertJsonToFormData(data);
    },
  ],
});

export const httpClientAuthInstance = httpClient({
  transformRequest: [
    (data, headers) => {
      headers["Accept"] = "application/json";
      headers["Content-Type"] = "application/json";
      headers["Authorization"] = `Bearer ${getItem("token")}`;
      headers["locale"] = i18n.language;

      return JSON.stringify(data);
    },
  ],
  validateStatus: function (status) {
    if (status === 401) {
      removeItem("token");
      removeItem("cart");
      window.location.reload();
    }
    return status >= 200 && status < 300;
  },
});

export const httpClientMultipartAuthInstance = httpClient({
  transformRequest: [
    (data, headers) => {
      headers["Accept"] = "application/json";
      headers["Content-Type"] = "multipart/form-data";
      headers["Authorization"] = `Bearer ${getItem("token")}`;
      headers["locale"] = i18n.language;

      return convertJsonToFormData(data);
    },
  ],
});

const httpClientBlobAuthInstance = httpClient({
  responseType: "blob",
  transformRequest: [
    (data, headers) => {
      headers["Accept"] = "application/json";
      headers["Content-Type"] = "multipart/form-data";
      headers["Authorization"] = `Bearer ${getItem("token")}`;
      headers["locale"] = i18n.language;

      return convertJsonToFormData(data);
    },
  ],
});

export const login = async (data) => {
  return await httpClientInstance.post("/customer/login", data);
};

export const invoiceLogin = async (data) => {
  return await httpClientInstance.post("/customer/invoice-login", data);
};

export const getBanners = async () => {
  return await httpClientAuthInstance.get("/banners");
};

export const getFeaturedStockList = async () => {
  return await httpClientAuthInstance.get("/stock-list/featured");
};

export const getCategories = async () => {
  return await httpClientAuthInstance.get("/categories");
};

export const getTopProducts = async (items) => {
  return await httpClientAuthInstance
    .get("/top-products", { params: { items } })
    .then((res) => {
      return res.data;
    });
};
export const getStockList = async (
  key,
  categories,
  availability,
  search,
  article_no,
  perPage,
  page = 1,
  ean = 1
) => {
  return await httpClientAuthInstance
    .get("/stock-list", {
      params: {
        page,
        categories,
        availability,
        search,
        article_no,
        per_page: perPage,
        ean,
      },
    })
    .then((res) => res.data);
};

export const getShippingAddressList = async () => {
  return await httpClientAuthInstance.get("/customer/address");
};

export const getCustomerProfile = async () => {
  return await httpClientAuthInstance.get("/customer/profile");
};

export const getCustomerYukaPoints = async () => {
  return await httpClientAuthInstance.get("/customer/yukapoints");
};

export const changeAuthCode = async (data) => {
  return await httpClientAuthInstance.put("/customer/change-authcode", data);
};

export const getMyOrders = async (
  key,
  page = 0,
  sort_by,
  sort_direction,
  status,
  search
) => {
  return await httpClientAuthInstance.get("/orders", {
    params: { page, sort_by, sort_direction, status, search },
  });
};

export const getCreditNotes = async (
  key,
  page = 0,
  sort_by,
  sort_direction,
  search
) => {
  return await httpClientAuthInstance.get("/credit-notes", {
    params: { page, sort_by, sort_direction, search },
  });
};

export const getInvoices = async (
  key,
  page = 0,
  sort_by,
  sort_direction,
  status,
  search
) => {
  return await httpClientAuthInstance.get("/invoices", {
    params: { page, sort_by, sort_direction, status, search },
  });
};

export const createOrder = async (data) => {
  return await httpClientAuthInstance.post("/order/create", data);
};

export const changePassword = async (data) => {
  return await httpClientAuthInstance.put("/customer/change-password", data);
};

export const getYukaPointProducts = async (key, page) => {
  return await httpClientAuthInstance.get("/yukapoint/products", {
    params: { page },
  });
};

export const createYukaPointOrder = async (data) => {
  return await httpClientAuthInstance.post("/yukapoint/order/create", data);
};

export const getOrderDetail = async (key, id, mode) => {
  return await httpClientAuthInstance.get(`/orders/${id}`, {
    params: { mode },
  });
};

export const getCreditNoteDetail = async (key, id) => {
  return await httpClientAuthInstance.get(`/credit-notes/${id}`);
};

export const getInvoiceDetail = async (key, id) => {
  return await httpClientAuthInstance.get(`/invoices/${id}`);
};

export const updateOrder = async ({ id, data }) => {
  return await httpClientAuthInstance.put(`/order/update/${id}`, data);
};

export const getBlob = async (url) => {
  return await httpClientBlobAuthInstance.get(url);
};

export const downloadInvoice = (id) => getBlob(`/invoices/download/${id}`);
export const downloadSerialNumber = (id) =>
  httpClientAuthInstance.get(`/invoices/download-serial-number/${id}`);

export const downloadCreditNotes = (id) =>
  getBlob(`/credit-notes/download/${id}`);

export const downloadOrder = (id) => getBlob(`/orders/download/${id}`);

export const lockOrder = async (key, id) => {
  return await httpClientAuthInstance.get(`/order/lock/${id}`);
};

export const deleteOrder = async ({ id }) => {
  return await httpClientAuthInstance.delete(`/order/delete/${id}`);
};

export const checkCOA = () =>
  httpClientAuthInstance.get("/coa/check").then((res) => res.data);

export const getInvoiceTrackingDetail = async (key, id) => {
  return await httpClientAuthInstance
    .get(`/invoices/tracking-number/${id}`)
    .then((res) => res.data);
};

export const getStockListCSV = async () => {
  return await httpClientAuthInstance.get(`/stock-list/whole`, {
    params: { type: "csv" },
    responseType: "blob",
  });
};

export const getStockListJSON = async () => {
  return await httpClientAuthInstance.get(`/stock-list/whole`, {
    params: { type: "json" },
  });
};

export const getRMA = async (key, page = 0, status, search) => {
  return await httpClientAuthInstance
    .get("/rma", {
      params: { page, search, status },
    })
    .then((res) => res.data);
};

export const getRmaDocument = async (id, type) => {
  return await httpClientAuthInstance.get(`/rma/download/${id}?type=${type}`, {
    responseType: "blob",
  });
};

export const getRmaDocumentPublic = async (id, type) => {
  return await httpClientInstance.get(
    `/rma/public/download/${id}?type=${type}`,
    {
      responseType: "blob",
    }
  );
};

export const checkEmail = async (data) => {
  return await httpClientInstance.post("/customer/forgot-password", data);
};

export const resetPassword = async (data) => {
  return await httpClientInstance.put("/customer/reset-password", data);
};

export const downloadDeliveryNote = (id) =>
  getBlob(`/invoices/download/${id}/delivery_note`);

export const getInvoiceBySerial = async (data) => {
  return await httpClientInstance.post("/rma/search/product-by-serial", data);
};

export const getInvoiceByInvoice = async (data) => {
  return await httpClientInstance.post("/rma/search/product-by-invoice", data);
};

export const createRma = async (data) => {
  return await httpClientInstance.post("/rma", data);
};

export const checkSerial = async (data) => {
  return await httpClientInstance.post("/rma/check-serial", data);
};

export const searchRma = async (key, search) => {
  return await httpClientInstance
    .get("/rma/search", {
      params: { search },
    })
    .then((res) => res.data);
};

export const createRmaReturn = async (data) => {
  return await httpClientInstance.post("/rma-return", data);
};

export const searchRmaReturnInvoice = async (data) => {
  return await httpClientInstance.post("/rma-return/search-invoice", data);
};

export const getExternalInvoices = async (
  key,
  page = 0,
  sort_by,
  sort_direction,
  status,
  search
) => {
  return await httpClientAuthInstance.get("/external-invoices", {
    params: { page, sort_by, sort_direction, status, search },
  });
};

export const downloadExternalInvoice = (id) =>
  getBlob(`/external-invoices/download/${id}`);

export const getRmaReturnDocument = async (id) => {
  return await httpClientInstance.get(`/rma-return/download/${id}`, {
    responseType: "blob",
  });
};

export const getSliders = async () => {
  return await httpClientInstance.get("/sliders");
};

export const getProductList = async (
  key,
  search,
  isDirectBuy,
  page = 1,
  perPage = 20
) => {
  return await httpClientAuthInstance
    .get(`show-case/products?v1`, {
      params: {
        search,
        is_direct_buy: isDirectBuy,
        page,
        per_page: perPage,
      },
    })
    .then((res) => res.data);
};

export const getCountries = async (page = 0, search) =>
  await httpClientAuthInstance
    .get(`countries?page=${page}&search=${search}`)
    .then((res) => res.data);

export const createTradeIn = async (data) =>
  await httpClientAuthInstance.post("tradein-order", data);

export const getPriceList = async () => {
  return await httpClientAuthInstance
    .get("/shop/pricelist")
    .then((res) => res.data);
};

export const downloadPriceList = async () => {
  return await httpClientAuthInstance
    .get("/shop/pricelist?type=xls", {
      responseType: "blob",
    })
    .then((response) => {
      const blob = new Blob([response.data], { type: "text/html" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "price-list.xls";
      link.click();
    });
};

export const createCustomerRecourse = async (data) => {
  return await httpClientInstance.post("customer/registration-request", data);
};

export const getCounterOffer = async () =>
  httpClientAuthInstance.get("/counter-offer").then((res) => res.data);

export const getTradeInOrder = async (status, page = 1, search) =>
  await httpClientAuthInstance
    .get("/tradein-order", {
      params: { status, page, search },
    })
    .then((res) => res.data);

export const tradeInOrderStatusChange = async (data) =>
  await httpClientAuthInstance.put("/tradein-order-item/change-status", data);

export const getTradeInInitialSubmission = async (id) =>
  await httpClientAuthInstance
    .get(`/tradein-order/initial-submission/${id}`)
    .then((res) => res.data);

export const getTradeInHistory = async (id) =>
  await httpClientAuthInstance
    .get(`/counter-offer/history/${id}`)
    .then((res) => res.data);

export const tradeInOrderOfferChange = async (data) =>
  await httpClientAuthInstance.post("/counter-offer", data);

export const getMessages = async (id, page = 1, limit = 20) =>
  await httpChatInstance.post(`/${id}/messages`, { page, limit });
